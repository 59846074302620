@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Global custom scrollbar styles */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #333; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded edges for the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background color of the scrollbar track */
}